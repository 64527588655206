@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

@font-face {
    font-family: "Roboto-Light";
    src: url("../public/fonts/Roboto-Light.woff");
  }

@font-face {
    font-family: "Roboto-Regular";
    src: url("../public/fonts/Roboto-Regular.woff");
  }

@font-face {
    font-family: "Roboto-md";
    src: url("../public/fonts/Roboto-Medium.woff");
  }